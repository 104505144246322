import React from "react";
import "./servicepage.css";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import ScrollToTop from "../ScrollToTop";

function ServicePage() {
  return (
    <ScrollToTop>
      <div style={{ marginTop: 30 }}>
        <Container>
          <Grid container spacing={2} className="main-grid" id="uiSolution">
            <Grid item xs={1}>
              <div className="servicevrlines"></div>
            </Grid>
            <Grid item xs={11}>
              <div className="serviceLandingSection">UI Solution</div>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <div className="serviceSmallLine"></div>
                </Grid>
                <Grid item xs={11}>
                  <div className="servicelandingSubSection">
                    "Creativity is just connecting things. When you ask creative
                    people how they did something, they feel a little guilty
                    because they didn't really do it, they just saw something.
                    It seemed obvious to them after a while." — Steve Jobs,
                    co-founder of Apple Inc.
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <img
          src="/images/S1.jpg"
          className="serviceImageone"
          alt="uisolution"
        />
        <Container>
          <div className="uicontent">
            We help you develop web apps that enables you to open new
            possibilities, market, and growth by perceptively aligning and
            serving your needs in market leading technologies (AngularJS,
            ReactJS, HTML, CSS, etc) and all other web technologies with
            best-fitting development factors, well-supporting knowledge
            resources and the right professionals that enable you develop open
            source software/ applications at scale. We are acclaimed for our
            ground-breaking web solutions in Java application development
            services, interactive & custom PHP solutions and AI / ML Cognitive
            solutions in Python and other cloud platform service offerings that
            are rich, productive, swift, reliable and high-performing.
          </div>
          <div>
            <Grid
              container
              spacing={2}
              className="mobility-heading"
              id="mobility"
            >
              <Grid item xs={1}>
                <div className="service-mobility-vrlines"></div>
              </Grid>
              <Grid item xs={11}>
                <div className="service-mobility-LandingSection">Mobility</div>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <div className="service-mobility-SmallLine"></div>
                  </Grid>
                  <Grid item xs={11}>
                    <div className="service-mobility-landingSubSection">
                      “The rich and interactive experiences we have come to
                      expect on mobile apps have created new standards and
                      expectations for all digital media including the web. The
                      result is websites are evolving to become more app-like in
                      their rich functionality.”--Raj Aggarwal, CEO of
                      Localytics
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
        <img
          src="/images/S2.jpg"
          className="mobility-serviceImageone"
          alt="uisolution"
        />
        <Container>
          <div className="mobility-uicontent">
            {" "}
            We architect, design, develop and deliver sought-after mobile native
            app systems on all native platforms and build hybrid apps in
            cross-platform app or web app by bringing in the design-led
            experiences at the forefront. We have rich expertise in mobile cloud
            computing which combines the mobile devices and cloud computing to
            create a new infrastructure, where cloud performs the heavy lifting
            of computing-intensive tasks and storing massive amounts of data.
          </div>
        </Container>
        <Container>
          <div>
            <Grid
              container
              spacing={2}
              className="apiIntegration-heading"
              id="apiIntegration"
            >
              <Grid item xs={1}>
                <div className="apiIntegration-vrlines"></div>
              </Grid>
              <Grid item xs={11}>
                <div className="apiIntegration-LandingSection">
                  API Integration
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <div className="apiIntegration-SmallLine"></div>
                  </Grid>
                  <Grid item xs={11}>
                    <div className="apiIntegration-landingSubSection">
                      With an API integration platform in place, organizations
                      can be sure they have the capabilities to effectively
                      integrate these new technologies as they grow and mature.
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
        <img
          src="/images/S3.jpg"
          className="apiIntegration-Imageone"
          alt="uisolution"
        />
        <Container>
          <div className="apiIntegration-uicontent">
            {" "}
            API integration is the connection between two or more applications
            via their APIs (application programming interfaces) that allow
            systems to exchange data sources. API integrations power processes
            throughout many sectors and layers of an organisation to keep data
            in sync, enhance productivity and drive revenue. API is an interface
            and is part and parcel of almost everything in the digital world. No
            matter the business and the size of the enterprise, APIs enable
            seamless operation and performance of applications and web systems.
          </div>
        </Container>
        <Container>
          <div>
            <Grid
              container
              spacing={2}
              className="apiTesting-heading"
              id="apiTesting"
            >
              <Grid item xs={1}>
                <div className="apiTesting-vrlines"></div>
              </Grid>
              <Grid item xs={11}>
                <div className="apiTesting-LandingSection">API Testing</div>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <div className="apiTesting-SmallLine"></div>
                  </Grid>
                  <Grid item xs={11}>
                    <div className="apiTesting-landingSubSection">
                      We offer comprehensive testing services that are powered
                      by strong domain & functional skills, fully automated &
                      code-less IPs / accelerated, and intelligently driven by
                      AI.
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
        <img
          src="/images/S4.jpg"
          className="apiTesting-Imageone"
          alt="uisolution"
        />
        <Container>
          <div className="apiTesting-uicontent">
            We bring in a lot of testing expertise and IPs / accelerators to
            test your backend modules even before your front-end components are
            built. Our unique tools and frameworks enable you go completely
            headless and complete the major chunk of API testing at very early
            phases of your product development.
          </div>
        </Container>
        <Container>
          <div>
            <Grid
              container
              spacing={2}
              className="productNServiceDesign-heading"
              id="ProductNService"
            >
              <Grid item xs={1}>
                <div className="productNServiceDesign-vrlines"></div>
              </Grid>
              <Grid item xs={11}>
                <div className="productNServiceDesign-LandingSection">
                  Product & Service Design
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <div className="productNServiceDesign-SmallLine"></div>
                  </Grid>
                  <Grid item xs={11}>
                    <div className="productNServiceDesign-landingSubSection">
                      Our design led design solution approach combines knowledge
                      with expertise, strategy with design and empathy with
                      creativity. We deliver through compelling stories, backed
                      by data and visualisations, that revolves around
                      human-centric experience design and design thinking tools.
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
        <img
          src="/images/S5.jpg"
          className="productNServiceDesign-Imageone"
          alt="uisolution"
        />
        <Container>
          <div className="productNServiceDesign-uicontent">
            {" "}
            We help organizations shape, build & launch their services &
            products that deliver experience driven moments for their consumers
            and employees across multiple touchpoints. We design or redesign the
            way the organisations provide services to their customers and
            transform the way they interact with the customers, users &
            stakeholders.
          </div>
        </Container>
        <Container>
          <div>
            <Grid
              container
              spacing={2}
              className="enterpriseNBussiness-heading"
              id="enterpriseNBussiness"
            >
              <Grid item xs={1}>
                <div className="enterpriseNBussiness-vrlines"></div>
              </Grid>
              <Grid item xs={11}>
                <div className="enterpriseNBussiness-LandingSection">
                  Enterprise & Business Transformation
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <div className="enterpriseNBussiness-SmallLine"></div>
                  </Grid>
                  <Grid item xs={11}>
                    <div className="enterpriseNBussiness-landingSubSection">
                      The aim is to make changes to processes, people or systems
                      (technology) to better align the company with its business
                      strategy and vision.
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
        <img
          src="/images/S6.jpg"
          className="enterpriseNBussiness-Imageone"
          alt="uisolution"
        />
        <Container>
          <div className="enterpriseNBussiness-uicontent">
            {" "}
            Beginning with an outside-in view, we help firms redefine
            strategies, bring in structural changes, develop innovation
            capabilities, identify new revenue streams, and design
            transformative business models. Our approach to do this is based on
            hypothesis, research and proven industry expertise.
          </div>
        </Container>
        <Container>
          <div className="getstarted">Get Started</div>
          <p className="getStartedContent">
            See how services from Auxtomate can help you meet challenges today
            and scale to meet your challenges tomorrow.
          </p>
          <Link to="/contact-us">
            {" "}
            <button className="serviceGetStartedButton">
              <span style={{ marginLeft: -50 }}> CONTACT US </span>
              <ArrowRightAltIcon
                style={{
                  color: "#007BFF",
                  fontSize: 50,
                  marginTop: -12,
                  marginLeft: 10,
                  position: "absolute",
                }}
              />
            </button>
          </Link>
        </Container>
      </div>{" "}
    </ScrollToTop>
  );
}

export default ServicePage;
