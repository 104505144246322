import "./App.css";
import Homepage from "./Components/Homepage/HomePage";
import Footer from "./Components/Footer/Footer";
import Privacy from "./Components/PrivacyPolicy/Privacy";
import Product from "./Components/Product/Product";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ServicePage from "./Components/Services/ServicePage";
import AboutUsPage from "./Components/Aboutus/AboutUsPage";
import TalkToUsForm from "./Components/Forms/TalkToUsForm";
import ContactSalesForm from "./Components/Forms/ContactSalesForm";
import Navbar from "./Components/Header/Navbar";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/product" element={<Product />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/talk-to-us" element={<TalkToUsForm />} />
          <Route path="/contact-us" element={<ContactSalesForm />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
