import { Card, Container, Grid, IconButton } from "@mui/material";
import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./service.css";
import { Link } from "react-router-dom";

const Service = () => {
  return (
    <div>
      <Container>
        <p className="serviceHeading">Services</p>
        <Grid container spacing={12}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="service-grid-main"
          >
            <Card className="serviceCard">
              <img
                src="/images/H4.jpg"
                alt="ui solution"
                className="serviceimages"
              />
              <div className="serviceCardText">
                <div className="hrliness" />
                <div>
                  <Container>
                    <p className="serviceSubHeading">UI solution</p>
                    <p className="servicecontent">
                      We help you develop web apps that enables you to open
                      new..
                    </p>
                  </Container>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <IconButton>
                    <Link to="service#uiSolution">
                      <ArrowRightAltIcon id="iconbutton" />
                    </Link>
                  </IconButton>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="service-grid"
          >
            <Card className="serviceCard">
              <img
                src="/images/H6.jpg"
                alt="ui solution"
                className="serviceimages"
              />
              <div className="serviceCardText">
                <div className="hrliness" />
                <div>
                  <Container>
                    <p className="serviceSubHeading">API Integration</p>
                    <p className="servicecontent">
                      API integration is the connection between two or more
                      applications..
                    </p>
                  </Container>
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <IconButton>
                    <Link to="service#uiSolution">
                      <ArrowRightAltIcon id="iconbutton" />
                    </Link>
                  </IconButton>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={12}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="service-grid-main"
          >
            <Card className="serviceCard">
              <img
                src="/images/H8.jpg"
                alt="ui solution"
                className="serviceimages"
              />
              <div className="serviceCardText">
                <div className="hrliness" />
                <div>
                  {" "}
                  <Container>
                    <p className="serviceSubHeading">
                      Product & Service Design
                    </p>
                    <p className="servicecontent">
                      We help organizations shape, build & launch their services
                      & products..
                    </p>
                  </Container>
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <IconButton>
                    <Link to="service#uiSolution">
                      <ArrowRightAltIcon id="iconbutton" />
                    </Link>
                  </IconButton>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="service-grid"
          >
            <Card className="serviceCard">
              <img
                src="/images/H5.jpg"
                alt="ui solution"
                className="serviceimages"
              />
              <div className="serviceCardText">
                <div className="hrliness" />
                <div>
                  <Container>
                    {" "}
                    <p className="serviceSubHeading">Mobility</p>
                    <p className="servicecontent">
                      We architect, design, develop and deliver sought - after
                      mobile
                    </p>
                  </Container>
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <IconButton>
                    <Link to="service#uiSolution">
                      <ArrowRightAltIcon id="iconbutton" />
                    </Link>
                  </IconButton>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={12}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="service-grid-main"
          >
            <Card className="serviceCard">
              <img
                src="/images/H7.jpg"
                alt="ui solution"
                className="serviceimages"
              />
              <div className="serviceCardText">
                <div className="hrliness" />
                <div>
                  <Container>
                    {" "}
                    <p className="serviceSubHeading">API Testing</p>
                    <p className="servicecontent">
                      We bring in a lot of testing expertise and IPs /
                      accelerators to test your backend..
                    </p>
                  </Container>
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <IconButton>
                    <Link to="service#uiSolution">
                      <ArrowRightAltIcon id="iconbutton" />
                    </Link>
                  </IconButton>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="service-grid"
          >
            <Card className="serviceCard">
              <img
                src="/images/H9.jpg"
                alt="ui solution"
                className="serviceimages"
              />
              <div className="serviceCardText">
                <div className="hrliness" />
                <div>
                  <Container>
                    <p className="serviceSubHeading">
                      Enterprise & Business Transformation
                    </p>
                    <p className="servicecontent">
                      Beginning with an outside-in view, we help firms redefine
                      strategies..
                    </p>
                  </Container>
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <IconButton>
                    <Link to="service#uiSolution">
                      <ArrowRightAltIcon id="iconbutton" />
                    </Link>
                  </IconButton>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Service;
