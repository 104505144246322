import { Container } from "@mui/material";
import React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import DrawerComponent from "./DrawerComponent";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div style={{ marginTop: 25 }}>
      <Container style={{ padding: "inherit" }}>
        <AppBar
          position="static"
          style={{
            backgroundColor: "#252525",
            boxShadow: "1px 1px 1px #252525",
          }}
        >
          <Toolbar>
            <Link to="/" className="nav-logo">
              <img
                src="/images/Logo.jpg"
                alt="logo-img"
                className="navbarImage"
              ></img>
            </Link>
            {isMobile ? (
              <DrawerComponent />
            ) : (
              <div className="links-main">
                <Link to="/about-us" className="about-links">
                  ABOUT US &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Link>
                <Link to="/product" className="product-links">
                  PRODUCTS &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Link>
                <Link to="/service" className="service-links">
                  SERVICES
                </Link>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </Container>
    </div>
  );
};

export default Navbar;
