import { Container, Grid } from "@mui/material";
import React from "react";
import "./aboutus.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <p className="aboutusHeading">About Auxtomate</p>
          <p className="aboutushrline"></p>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <p className="aboutusSubHeading">
            We are a technology and business transformation accelerator, where
            Automation, UX & Design combine to deliver experience led &
            innovative technology products, services & solutions for our
            customers. We are highly focused on bringing a gamut of low-code and
            no-code products and outstanding services at highly flexible pricing
            models.
          </p>
        </Grid>
      </Grid>
      <div>
        <Link to="/about-us" style={{ textDecoration: "none" }}>
          <button className="aboutusBtn">
            GET TO KNOW US
            <ArrowRightAltIcon
              style={{
                color: "#007BFF",
                fontSize: 50,
                marginTop: -12,
                marginLeft: 10,
                position: "absolute",
              }}
            />
          </button>
        </Link>
      </div>
    </Container>
  );
};

export default AboutUs;
