import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { Icon } from "@iconify/react";
import ScrollToTop from "../ScrollToTop";

// import PhoneIcon from '@mui/icons-material/Phone';

const Footer = () => {
  return (
    <ScrollToTop>
      <div className="footer-main">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <img
                src="/images/Logo.jpg"
                alt="footer logo"
                className="footer-logo"
              />
              <p className="footer-content">
                At our core, Auxtomate is a business and technology
                transformation accelerator, where Automation, Cognitive & Design
                combines to deliver experience led low-code / no-code platforms,
                services and solutions for our customers quite rapidly.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <p className="footer-links-main">LINKS</p>
              <div className="footer-links">
                <Link style={{ textDecoration: "none" }} to="/about-us">
                  <div className="footer-about">About Us </div>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/service">
                  <div className="footer-service">Services</div>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/product">
                  <div className="footer-product">Products</div>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/privacy-policy">
                  <div className="footer-privacy">Privacy Policy</div>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/">
                  <div className="footer-sitemap">Sitemap</div>
                </Link>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4}>
              <p className="footer-contact-main">LET'S TALK</p>
              <div>
                <a
                  className="footer-mail-id"
                  href="mailto:contact@auxtomate.com?subject=New%20Enquiry%20from%20the%20website"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  contact@auxtomate.com
                </a>
                <div className="footer-mail-underline"></div>
                <div className="footer-mobile">
                  <Icon
                    icon="bi:phone-fill"
                    style={{ fontSize: 20, color: "#FCF113" }}
                  />
                  <a
                    href="tel:+91-9902311787"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    +91-9902311787{" "}
                  </a>
                </div>
                <div className="footer-address-main">
                  <Icon
                    icon="carbon:location-filled"
                    style={{ fontSize: 20, color: "#FCF113", marginTop: 5 }}
                  />
                  <span className="footer-address">
                    Auxtomate Technologies pvt ltd A9, Sainath Foundation, First
                    cross street, Dillibabu Nagar, Pallikaranai, Chennai-600100
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="footer-icon-underline"></div>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                <p className="footer-copyright-text">
                  © 2020 auxtomate Technologies. All Right Reserved. Design and
                  Developed by auxtomate Technologies.
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <div className="footer-icons-main">
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={3} md={3} lg={1}>
                      <span>
                        <Icon icon="entypo-social:linkedin-with-circle" />
                      </span>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={1}>
                      <span>
                        <Icon icon="akar-icons:facebook-fill" />
                      </span>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={1}>
                      <span>
                        <Icon icon="ant-design:instagram-filled" />
                      </span>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={1}>
                      <span>
                        <Icon icon="entypo-social:twitter-with-circle" />
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </ScrollToTop>
  );
};

export default Footer;
