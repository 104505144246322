import React from "react";
import "./product.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";


function Product() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <ScrollToTop>
      <div style={{ marginTop: 30 }}>
        <Container>
          <Grid container spacing={2} className="product-main-first-grid">
            <Grid item xs={1}>
              <div className="productvrlines"></div>
            </Grid>
            <Grid item xs={11}>
              <div className="productLandingSection">
                The only, No-Code Partner APIs Integration solution in the
                market.
              </div>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <div className="productSmallLine"></div>
                </Grid>
                <Grid item xs={11}>
                  <div className="productlandingSubSection">
                    Unleash the power of <strong>APINATOR </strong> and
                    kick-start your business at breakneck speed. Not just that,
                    APINATOR suite of products offers much more than API
                    Integration
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <div>
          <iframe
            className="productVideo"
            src="https://www.auxtomate.com/videos/Commercial-for-Apinator.mp4"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
          />
        </div>
        <Container>
          <div>
            <p className="videoDescription">
              With the APINATOR suite of products, you can rapidly onboard,
              consume and manage your Partner APIs E2E with no human
              intervention for the entire life-time. Also, it helps SMB to build
              & launch enterprise applications in no time.
            </p>
          </div>
          <div className="productButton-main">
            <Link to="/contact-us">
              <button className="productButton">
                <span style={{ marginLeft: -50 }}> CONTACT US </span>
                <ArrowRightAltIcon
                  style={{
                    color: "#007BFF",
                    fontSize: 50,
                    marginTop: -12,
                    marginLeft: 10,
                    position: "absolute",
                  }}
                />
              </button>
            </Link>
          </div>
        </Container>
        <div>
          <img
            src="/images/P2.png"
            className="productFirstImage"
            alt="productfirst"
          />
        </div>
        <Container>
          <div className="firstImageDescription">
            Deliver an enriched experience by supporting every aspect of APIs
            onboarding and consumption business on a single platform.
          </div>
          <div className="qualityGrid-main">
            <Grid container className="qualityGrid" spacing={2}>
              <Grid item xs={12} md={4}>
                <AvTimerIcon
                  className="qualityGrid-icons"
                  style={{ color: "#FCF113", fontSize: 50 }}
                />
                <p className="ProductGridTitle">Fast to Deploy</p>
                <div className="ProductGrid">
                  All in on capabilities, low/no-code configuration, pre-built
                  domain and industry content enable you to get up and running
                  quickly
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <CurrencyRupeeIcon
                  className="qualityGrid-icons"
                  style={{ color: "#FCF113", fontSize: 50 }}
                />
                <p className="ProductGridTitle">Low Cost of Ownership</p>
                <div className="ProductGrid">
                  Consumption based pricing, AI powered automation, and
                  intuitive user interfaces enable you to increase productivity
                  and efficiency
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <TrendingUpIcon
                  className="qualityGrid-icons"
                  style={{ color: "#FCF113", fontSize: 50 }}
                />
                <p className="ProductGridTitle"> High Return on Investment</p>
                <div className="ProductGrid">
                  Ability to eliminate multiple technical roles, includes
                  delivering superior security, high performance at an enhanced
                  scale.
                </div>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: 100 }}>
            {/* ----------apinatior enter */}
            <Container>
              <Grid container spacing={2} className="newProductGrid">
                <Grid item xs={12} lg={8} className="aboutus">
                  <strong className="apinatorProductHeading">
                    APINATOR - Enterprise
                  </strong>
                </Grid>
                <Container>
                  <div className="apinatorProductContentMain">
                    <div className="apinatorProductContent">
                      The one-stop, E2E automated and highly configurable API
                      integration solution for any aggregation business that
                      functions by consuming external APIs.
                    </div>

                    <Grid item xs={12} lg={4}>
                      <div>
                        <img
                          className="productImageRight"
                          src="/images/Enterprise.jpg"
                          alt="APINATOR - Enterprise"
                        />
                      </div>
                    </Grid>
                  </div>
                </Container>
              </Grid>
            </Container>
            {/* ----------apinatior enter */}

            {/* =-----------------apinator form----------- */}
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                  <strong className="apinatorform-Heading">
                    APINATOR - Form
                  </strong>
                </Grid>
                <Container>
                  <div className="apinatorProductContentMainLeft">
                    <div className="apinatorProductContent">
                      Build and launch forms & associated Databases in a matter
                      of few minutes by using our E2E automated solution. All it
                      takes is to input the fields needed for your business and
                      it's highly tech-agnostic.
                    </div>

                    <Grid item xs={12} lg={4}>
                      <div>
                        <img
                          className="productImageLeft"
                          src="/images/Form.jpg"
                          alt=" APINATOR - Form"
                        />
                      </div>
                    </Grid>
                  </div>
                </Container>
              </Grid>
            </Container>
            {/* =-----------------apinator form----------- */}

            {/* ==========apinator form X========== */}
            <Container>
              <Grid container spacing={2} className="newProductGrid">
                <Grid item xs={12} lg={8} className="aboutus">
                  <strong className="apinatorform-x-tHeading">
                    APINATOR - FormX
                  </strong>
                </Grid>
                <Container>
                  <div className="apinatorProductContentMain">
                    <div className="apinatorProductContent">
                      Not just building the forms, you can integrate with the
                      external APIs through simple configuration without any
                      technical intervention.
                    </div>

                    <Grid item xs={12} lg={4}>
                      <div>
                        <img
                          className="productImageRight"
                          src="/images/Form X.jpg"
                          alt="APINATOR - FormX"
                        />
                      </div>
                    </Grid>
                  </div>
                </Container>
              </Grid>
            </Container>

            {/* ==========apinator form X========== */}

            {/* =-----------------work Flow----------- */}
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                  <strong className="apinatorworkflow-Heading">
                    APINATOR - Workflow
                  </strong>
                </Grid>
                <Container>
                  <div className="apinatorProductContentMainLeft">
                    <div className="apinatorProductContent-Workflow">
                      Build automated enterprise applications in a highly rapid
                      manner and auto-integrate with Databases or APIs without
                      writing code, configured through simple front-end screens.
                    </div>

                    <Grid item xs={12} lg={4}>
                      <div>
                        <img
                          className="productImageLeft"
                          src="/images/Workflow.jpg"
                          alt=" APINATOR - Workflow"
                        />
                      </div>
                    </Grid>
                  </div>
                </Container>
              </Grid>
            </Container>
            {/* =-----------------work Flow----------- */}
          </div>
        </Container>
        <Container>
          <div className="productAccordianStyle">
            <Accordion
              style={{
                backgroundColor: "#252525",
                color: "#fff",
                borderTop: "1px solid #FCF113",
                borderBottom: "1px solid #FCF113",
              }}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FCF113" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "70%",
                    flexShrink: 0,
                    fontSize: 22,
                    wordSpacing: 20,
                  }}
                >
                  <strong> All-in-One Capabilities</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    width: "70%",
                    flexShrink: 0,
                    fontSize: 20,
                    wordSpacing: 10,
                  }}
                >
                  The most comprehensive capabilities integrated into a single
                  platform built with next-gen tech stack and is hosted on cloud
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "#252525",
                color: "#fff",
                borderTop: "1px solid #FCF113",
                borderBottom: "1px solid #FCF113",
              }}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FCF113" }} />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography
                  sx={{
                    width: "70%",
                    flexShrink: 0,
                    fontSize: 22,
                    wordSpacing: 20,
                  }}
                >
                  <strong> Onboard Instantly </strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    width: "70%",
                    flexShrink: 0,
                    fontSize: 20,
                    wordSpacing: 10,
                  }}
                >
                  Onboarding of APIs just takes a couple of days irrespective of
                  how complex the APIs are. All it takes is to configure right
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "#252525",
                color: "#fff",
                borderTop: "1px solid #FCF113",
                borderBottom: "1px solid #FCF113",
              }}
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FCF113" }} />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography
                  sx={{
                    width: "70%",
                    flexShrink: 0,
                    fontSize: 20,
                    wordSpacing: 10,
                  }}
                >
                  <strong> Auto-generated UIs </strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    width: "70%",
                    flexShrink: 0,
                    fontSize: 20,
                    wordSpacing: 10,
                  }}
                >
                  For the businesses which has to render UIs based on the APIs
                  workflow, the platform dynamically renders the UIs with
                  enriched UX.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "#252525",
                color: "#fff",
                borderTop: "1px solid #FCF113",
                borderBottom: "1px solid #FCF113",
              }}
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FCF113" }} />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography
                  sx={{
                    width: "70%",
                    flexShrink: 0,
                    fontSize: 20,
                    wordSpacing: 10,
                  }}
                >
                  <strong> Cost Savings</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    width: "70%",
                    flexShrink: 0,
                    fontSize: 20,
                    wordSpacing: 10,
                  }}
                >
                  Eliminates repetitive tasks and also the technical resources
                  who designs, develops, tests & deploys the API, UI & QA
                  components.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "#252525",
                color: "#fff",
                borderTop: "1px solid #FCF113",
                borderBottom: "1px solid #FCF113",
              }}
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FCF113" }} />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography
                  sx={{
                    width: "70%",
                    flexShrink: 0,
                    fontSize: 22,
                    wordSpacing: 20,
                  }}
                >
                  <strong> Lifecycle Automated</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    width: "70%",
                    flexShrink: 0,
                    fontSize: 20,
                    wordSpacing: 10,
                  }}
                >
                  Maintenance of E2E API consumption cycle is automated on Day
                  1. Change Requests gets handled in a matter of few hours
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Container>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={7}>
              <p className="getStarted-heading">Get Started</p>
              <p className="getstartedContent">
                See for yourself how APINATOR can help you and your business
                meet challenges today and scale to meet your challenges
                tomorrow.
              </p>
            </Grid>
            <Grid item xs={4}>
              <img
                className="productGetStarted-image"
                src="/images/P4.png"
                alt="getstarted"
              />
            </Grid>
          </Grid>
        </div>
      </div>{" "}
    </ScrollToTop>
  );
}

export default Product;
