import { Button, Card, Container, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import AboutUs from "./AboutUs";
import Clients from "./Clients";
import ExploreProduct from "./ExploreProduct";
import "./homepage.css";
import Service from "./Service";

function Homepage() {
  return (
    <ScrollToTop>
      <div className="homepage-content-background-img">
        <Container>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <p className="homepageHeading">
                <span style={{ color: "#8BAFDF" }}> Integrate</span> &{" "}
                <span style={{ color: "#8BAFDF" }}>Onboard </span> APIs in a
                flash using our E2E automated solution. Auto-Render UI Forms and
                integrate to your backend systems instantly.
              </p>
            </Grid>
          </Grid>
          <div className="homepagehrline"></div>{" "}
          <Container>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <p className="homepageSubHeading">
                  Our<span style={{ color: "#8BAFDF" }}> solution </span>
                  delivers instant integration & onboarding of APIs through
                  configurable interfaces, eliminates manual build efforts,
                  enables E2E API lifecycle management at the consumer end and
                  generates dynamic UIs automatically with no compromise on the
                  UX.
                </p>
              </Grid>
            </Grid>
            <div>
              <Link to="/talk-to-us" style={{ textDecoration: "none" }}>
                <button className="homepageBtn">Talk To Us</button>
              </Link>
            </div>
          </Container>
        </Container>
      </div>
      <div>
        <img src="/images/H1.jpg" alt="first" className="homepageFirstimage" />
      </div>
      <AboutUs />
      <ExploreProduct />
      <Service />
      <Clients />
    </ScrollToTop>
  );
}

export default Homepage;
