import React, { useState } from "react";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import "./navbar.css";
import { Icon } from "@iconify/react";

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/about-us" style={{ textDecoration: "none" }}>
                ABOUT US
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/product" style={{ textDecoration: "none" }}>
                PRODUCTS
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/service" style={{ textDecoration: "none" }}>
                SERVICES
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
        </List>
      </Drawer>
      <div className="drawer-icon">
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
          <Icon
            icon="ci:menu-alt-01"
            style={{ color: "#2378e9", fontSize: 45 }}
          />
        </IconButton>
      </div>
    </>
  );
}
export default DrawerComponent;
