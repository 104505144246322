import { Card, Container, Grid } from "@mui/material";
import React from "react";
import "./clients.css";

function Clients(props) {

  let style={
    height:"100%",
    width:"100%"
  }
  return (
    <>
      <Card className="clientsCard">
        <p className="clientsHeading">Clients</p>
        <Container>
          <Grid
            container
            spacing={2}
            style={{ justifyContent: "space-evenly", marginTop: 50 }}
          >
            <Grid item xs={12} lg={4}>
              <img className="philip " src="/images/1.png" alt="philips" />
            </Grid>
            <Grid item xs={12} lg={4} style={{ marginTop: 25 }}>
              <img className=" nasper" src="/images/2.png" alt="nasper" />
            </Grid>
            <Grid item xs={12} lg={4} style={{ marginTop: 25 }}>
              <img className=" vodafone" src="/images/3.png" alt="vodafone" />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              justifyContent: "space-evenly",
              marginTop: 25,
              marginBottom: 50,
            }}
          >
            <Grid item xs={12} lg={4}>
              <img className=" ford" src="/images/Logo_Qs.png" alt="ford" />
            </Grid>
            <Grid item xs={12} lg={4} style={{ marginTop: 25 }}>
              <img className=" cisco" src="/images/5.png" alt="cisco" />
            </Grid>
            <Grid item xs={12} lg={4} style={{ marginTop: 25 }}>
              <img className="cisco2 " src="/images/6.png" alt="cisco" />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{
              justifyContent: "space-evenly",
              marginTop: 25,
              marginBottom: 50,
            }}
          >
            <Grid item xs={12} lg={4}>
              <img className=" ford" src="/images/Logo_Nudge.png" alt="ford" />
            </Grid>
            <Grid item xs={12} lg={4} style={{ marginTop: 0}}>
              <img className=" cisco" src="/images/4.png" alt="cisco"/>
            </Grid>
            <Grid item xs={12} lg={4} style={{ marginTop: 25,display:"flex",alignItems:"center",justifyContent:"center"}}>
              <img className="cisco2 " src="/images/Logo_TC.png" alt="cisco" />
            </Grid>
          </Grid>
        </Container>
      </Card>
      <div className="clientsbussinessoutcome-main">
        <Container>
          <Container>
            <p className="clientsbussinessoutcome-heading">
              Deliver incremental business outcomes...
            </p>
          </Container>
        </Container>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <img
              src="/images/H10.png"
              alt="design1"
              className="clientsimageone"
            />
          </Grid>
          <Grid item xs={6}>
            <img
              src="/images/H11.png"
              alt="design2"
              className="clientsimagesecond"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Clients;
