import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import "./aboutusPage.css";
import Slider from "react-slick";
import ScrollToTop from "../ScrollToTop";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AboutUsPage = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <ScrollToTop>
      <div style={{marginTop:30}}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <div className="aboutUsvrlines"></div>
            </Grid>
            <Grid item xs={11}>
              <div className="aboutUs-heading">
                Delivering quick hit outcomes through cognitive and automated
                solutions
              </div>
            </Grid>
          </Grid>
        </Container>
        <div>
          <img
            src="/images/A1.jpg"
            className="aboutUs-Firstimage"
            alt="aboutusfirstimage"
          />
        </div>
        <Container>
          <div>
            <p className="aboutUs-content">
              We iterate ideas and solutions to uncover new ways to create value
              by applying technology, domain and design intelligence. In the
              services area, we come across as a SMB & start-up accelerator to
              deliver quick hit outcomes that drive efficiency and uncommon
              innovation. In the products space, we are highly focused to create
              a powerful and collaborative space to solve in-demand challenges
              through the automated solutions.
            </p>
          </div>
        </Container>
        <div>
          <img
            className="aboutUs-Secondimage"
            alt="aboutussecondimage"
            src="/images/A2.jpg"
          />
        </div>
        <Container>
          <div className="focus-main">
            <Grid container spacing={2}>
              <Grid item xs={1} sm={1}>
                <div className="smallhrline"></div>
              </Grid>
              <Grid item xs={3}>
                <p className="focusedonsectionauxtomate">auxtomate</p>
                <p className="focusedonheading">Our Focus</p>
              </Grid>
              <Grid item xs={1}>
                <div className="focushrlines"></div>
              </Grid>
              <Grid item xs={7} sm={7}>
                <p>
                  <CenterFocusStrongIcon
                    className="focus-icon"
                    style={{ color: "#FCF113" }}
                  />
                </p>
                <p className="focussectioncontent">
                  We are highly focussed on partnering with start-up and SMBs
                  and help them succeed on their objectives. We bring in a
                  variety of high flexible pricing models for them to deliver
                  incremental business outcomes.
                </p>
              </Grid>
            </Grid>
          </div>

          <div className="AccordianStyle">
            <Accordion
              style={{
                backgroundColor: "#252525",
                color: "#fff",
                borderTop: "1px solid #FCF113",
                borderBottom: "1px solid #FCF113",
              }}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FCF113" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "95%",
                    flexShrink: 0,
                    fontSize: 16,
                    wordSpacing: 5,
                  }}
                  className="accordianTitle"
                >
                  Multiple Partner APIs integration for a large insurance
                  brokerage vendor
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    width: "95%",
                    flexShrink: 0,
                    fontSize: 16,
                    wordSpacing: 5,
                  }}
                  className="accordianContent"
                >
                  Using APINATOR Enterprise we have onboarded multiple insurance
                  products for our client in a leaner timeline and cut down
                  their effort cost by 6x on design, build & maintain their
                  Partner APIs
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "#252525",
                color: "#fff",
                borderTop: "1px solid #FCF113",
                borderBottom: "1px solid #FCF113",
              }}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FCF113" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "95%",
                    flexShrink: 0,
                    fontSize: 16,
                    wordSpacing: 5,
                  }}
                  className="accordianTitle"
                >
                  E-learning backend apps for one of a leading learning
                  platforms
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    width: "95%",
                    flexShrink: 0,
                    fontSize: 16,
                    wordSpacing: 5,
                  }}
                  className="accordianContent"
                >
                  Re-imagination of CXs are through custom open source
                  front-ends and most the backend apps are being developed using
                  the low-code BPM solution integrated to the core apps through
                  RestAPI calls
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "#252525",
                color: "#fff",
                borderTop: "1px solid #FCF113",
                borderBottom: "1px solid #FCF113",
              }}
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FCF113" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  className="accordianTitle"
                  sx={{
                    width: "95%",
                    flexShrink: 0,
                    fontSize: 16,
                    wordSpacing: 5,
                  }}
                >
                  Custom CRM apps for large reality and student loan lending
                  players
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    width: "95%",
                    flexShrink: 0,
                    fontSize: 16,
                    wordSpacing: 5,
                  }}
                  className="accordianContent"
                >
                  Built custom CRM and Sales life-cycle apps for our clients
                  using the open source technologies and hosted the same on
                  Cloud
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "#252525",
                color: "#fff",
                borderTop: "1px solid #FCF113",
                borderBottom: "1px solid #FCF113",
              }}
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FCF113" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "95%",
                    flexShrink: 0,
                    fontSize: 16,
                    wordSpacing: 5,
                  }}
                  className="accordianTitle"
                >
                  E2E Digitization of applications for one of a large life
                  science customer
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    width: "95%",
                    flexShrink: 0,
                    fontSize: 16,
                    wordSpacing: 5,
                  }}
                  className="accordianContent"
                >
                  Designed and built E2E digitized platform by integrating all
                  siloed applications of their nutravedics businessx
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Container>
        <div className="parentCarousalContainer">
          <Container>
            <div className="carouselStyle">
              <Slider {...settings} className="item" rows={1}>
                <div className="CardCarousel">
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <p className="client-testimonials-heading">
                        Client Testimonials
                      </p>
                    </Grid>
                    <Grid item xs={1}>
                      <div className="hrlineaboutus"></div>
                    </Grid>
                    <Grid item xs={7}>
                      <p className="worldEducation">Worldwide Education </p>
                      <div className="worldEducation-content">
                        Auxtomate helped us accelerate our company's complete
                        sales lifecycle process through technology in the time
                        committed. Pleasure working with you.
                      </div>
                      <p>- Muralikrishnan</p>
                    </Grid>
                  </Grid>
                </div>
                <div className="CardCarousel">
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <p className="client-testimonials-heading">
                        Client Testimonials
                      </p>
                    </Grid>
                    <Grid item xs={1}>
                      <div className="hrlineaboutus"></div>
                    </Grid>

                    <Grid item xs={7}>
                      <p className="worldEducation">Sage Nutravedics</p>
                      <div className="worldEducation-content">
                        Thanks for digitizing our backend operations, we are now
                        faster, leaner and seamless in our business development.
                      </div>
                      <p>- Raju</p>
                    </Grid>
                  </Grid>
                </div>

                <div className="CardCarousel">
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <p className="client-testimonials-heading">
                        {" "}
                        Client Testimonials
                      </p>
                    </Grid>
                    <Grid item xs={1}>
                      <div className="hrlineaboutus"></div>
                    </Grid>
                    <Grid item xs={7}>
                      <p className="worldEducation">Acuvisor </p>
                      <div className="worldEducation-content">
                        Through the common API approach, we have become faster
                        in onboarding our Partner APIs in our platform. Thanks
                        for the support.
                      </div>
                      <p>- Durga</p>
                    </Grid>
                  </Grid>
                </div>
              </Slider>
            </div>
          </Container>
        </div>

        <div className="aboutusbussinessoutcome-main">
          <Container>
            <Container>
              <p className="aboutusbussinessoutcome-heading">
                Deliver incremental business outcomes...
              </p>
            </Container>
          </Container>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <img
                src="/images/H10.png"
                alt="design1"
                className="aboutusimageone"
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src="/images/H11.png"
                alt="design2"
                className="aboutusimagesecond"
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </ScrollToTop>
  );
};
export default AboutUsPage;
