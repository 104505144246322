import { Container, Grid } from "@mui/material";
import React from "react";
import "./privacy.css";

const Privacy = () => {
  return (
    <div
      style={{
        marginTop: 30,
      }}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <div className="privacyvrlines"></div>
          </Grid>
          <Grid item xs={11}>
            <div className="privacy-heading">Privacy Policy</div>
            <span className="update-date">Last updated: October 18, 2022</span>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            <div className="privacy-landingSubSection">
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You. We use Your Personal data to provide and improve
              the Service. By using the Service, You agree to the collection and
              use of information in accordance with this Privacy Policy.
            </div>
          </Grid>
        </Grid>
        <div className="interpretation-heading-main">
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <span className="horizontal-Line-left"></span>
            </Grid>
            <Grid item xs={8}>
              <span className="interpretation-text-content-main">
                Interpretation and Definitions
              </span>
            </Grid>
            <Grid item xs={2}>
              <span className="horizontal-Line-right"></span>
            </Grid>
          </Grid>
        </div>
        <div>
          <span className="interpretation-Sub-heading">Interpretation</span>
          <p className="interpretation-Sub-content">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
        </div>
        <div>
          <span className="defination-heading">Definitions</span>
          <p className="defination-Sub-heading">
            For the purposes of this Privacy Policy:
          </p>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>Account</span>
                &nbsp;&nbsp; means a unique account created for You to access
                our Service or parts of our Service.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>Company</span>
                &nbsp;&nbsp; (referred to as either &quot;the Company&quot;,
                &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                Agreement) refers to Auxtomate Technologies, A-9, Sainath
                Foundation, Dillibabu Nagar, Pallikaranai, Chennai 600100.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>Cookies</span>
                &nbsp;&nbsp; are small files that are placed on Your computer,
                mobile device or any other device by a website, containing the
                details of Your browsing history on that website among its many
                uses.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>Country</span>
                &nbsp;&nbsp; refers to: Tamil Nadu, India.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>Device</span>
                &nbsp;&nbsp; means any device that can access the Service such
                as a computer, cellphone or a digital tablet.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>Personal Data</span>
                &nbsp;&nbsp; is any information that relates to an identified or
                identifiable individual.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>Service</span>
                &nbsp;&nbsp; refers to the Website.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>Service Provider</span>
                &nbsp;&nbsp; means any natural or legal person who processes the
                data on behalf of the Company. It refers to third-party
                companies or individuals employed by the Company to facilitate
                the Service, to provide the Service on behalf of the Company, to
                perform services related to the Service or to assist the Company
                in analyzing how the Service is used.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>Usage Data</span>
                &nbsp;&nbsp; refers to data collected automatically, either
                generated by the use of the Service or from the Service
                infrastructure itself (for example, the duration of a page
                visit).
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>Website</span>
                &nbsp;&nbsp; refers to Auxtomate Technologies, accessible from
                https://auxtomate.com
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>You</span>
                &nbsp;&nbsp; You means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </Grid>
          </Grid>
        </div>
        <div>
          <span className="personal-data-heading">
            Collecting and Using Your Personal Data
          </span>
          <p className="personal-data-Sub-heading-main">
            Types of Data Collected
          </p>
          <p className="personal-data-heading-sub" style={{ color: "#8BAFDF" }}>
            Personal Data
          </p>
          <p className="personal-data-content">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <p className="defination-Sub-contents">Email address</p>
              <p className="defination-Sub-contents">
                First name and last name
              </p>
              <p className="defination-Sub-contents">Phone number</p>
              <p className="defination-Sub-contents">Usage Data</p>
            </Grid>
          </Grid>
        </div>
        <div>
          <span
            className="usage-data-heading-main"
            style={{ color: "#8BAFDF" }}
          >
            Usage Data
          </span>
          <p className="usage-data-content">
            Usage Data is collected automatically when using the Service.
          </p>
          <p className="usage-data-content">
            Usage Data may include information such as Your Device&#39;s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that You visit, the time and date
            of Your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
          <p className="usage-data-content">
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p className="usage-data-content">
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
        </div>
        <div>
          <p className="tracking-technologies-heading">
            Tracking Technologies and Cookies
          </p>
          <p className="tracking-technologies-sub">
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </p>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  Cookies or Browser Cookies &nbsp;&nbsp;
                </span>
                A cookie is a small file placed on Your Device. You can instruct
                Your browser to refuse all Cookies or to indicate when a Cookie
                is being sent. However, if You do not accept Cookies, You may
                not be able to use some parts of our Service. Unless you have
                adjusted Your browser setting so that it will refuse Cookies,
                our Service may use Cookies.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  Web Beacons. &nbsp;&nbsp;
                </span>
                Certain sections of our Service and our emails may contain small
                electronic files known as web beacons (also referred to as clear
                gifs, pixel tags, and single-pixel gifs) that permit the
                Company, for example, to count users who have visited those
                pages or opened an email and for other related website
                statistics (for example, recording the popularity of a certain
                section and verifying system and server integrity).
              </p>
            </Grid>
          </Grid>
          <p className="usage-data-content">
            Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
            Cookies. Persistent Cookies remain on Your personal computer or
            mobile device when You go offline, while Session Cookies are deleted
            as soon as You close Your web browser. Learn more about cookies on
            the Privacy Policies website article.
          </p>
          <p className="usage-data-content">
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  Necessary / Essential Cookies &nbsp;&nbsp;
                </span>
              </p>
              <p className="usage-data-content">
                <strong>Type: </strong>Session Cookies
              </p>
              <p className="usage-data-content">
                <strong>Administered by:</strong> Us
              </p>
              <p className="usage-data-content">
                <strong> Purpose:</strong> These Cookies are essential to
                provide You with services available through the Website and to
                enable You to use some of its features. They help to
                authenticate users and prevent fraudulent use of user accounts.
                Without these Cookies, the services that You have asked for
                cannot be provided, and We only use these Cookies to provide You
                with those services.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  Cookies Policy / Notice Acceptance Cookies &nbsp;&nbsp;
                </span>
              </p>
              <p className="usage-data-content">
                <strong>Type:</strong> Persistent Cookies
              </p>
              <p className="usage-data-content">
                <strong>Administered by:</strong> Us
              </p>
              <p className="usage-data-content">
                <strong>Purpose:</strong> These Cookies identify if users have
                accepted the use of cookies on the Website.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  Functionality Cookies &nbsp;&nbsp;
                </span>
              </p>
              <p className="usage-data-content">
                <strong>Type:</strong> Persistent Cookies
              </p>
              <p className="usage-data-content">
                <strong>Administered by:</strong> Us
              </p>
              <p className="usage-data-content">
                <strong>Purpose:</strong> These Cookies allow us to remember
                choices You make when You use the Website, such as remembering
                your login details or language preference. The purpose of these
                Cookies is to provide You with a more personal experience and to
                avoid You having to re-enter your preferences every time You use
                the Website.
              </p>
            </Grid>
          </Grid>
          <p className="usage-data-content">
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
        </div>
        <div>
          <p className="persnal-data-heading">Use of Your Personal Data</p>
          <p className="persnal-data-sub-heading">
            The Company may use Personal Data for the following purposes:
          </p>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  To provide and maintain our Service, &nbsp;&nbsp;
                </span>
                including to monitor the usage of our Service.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  To manage Your Account: &nbsp;&nbsp;
                </span>
                manage Your registration as a user of the Service. The Personal
                Data You provide can give You access to different
                functionalities of the Service that are available to You as a
                registered user.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  For the performance of a contract: &nbsp;&nbsp;
                </span>
                the development, compliance and undertaking of the purchase
                contract for the products, items or services You have purchased
                or of any other contract with Us through the Service.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  To contact You: &nbsp;&nbsp;
                </span>
                To contact You by email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as a mobile
                application&#39;s push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  To provide You &nbsp;&nbsp;
                </span>
                with news, special offers and general information about other
                goods, services and events which we offer that are similar to
                those that you have already purchased or enquired about unless
                You have opted not to receive such information..
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  To manage Your requests: &nbsp;&nbsp;
                </span>
                To attend and manage Your requests to Us.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  For business transfers: &nbsp;&nbsp;
                </span>
                We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of Our assets, whether as
                a going concern or as part of bankruptcy, liquidation, or
                similar proceeding, in which Personal Data held by Us about our
                Service users is among the assets transferred.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  For other purposes: &nbsp;&nbsp;
                </span>
                We may use Your information for other purposes, such as data
                analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and
                improve our Service, products, services, marketing and your
                experience.
              </p>
            </Grid>
          </Grid>
          <p className="persnal-data-sub-heading">
            We may share Your personal information in the following situations:
          </p>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  With Service Providers: &nbsp;&nbsp;
                </span>
                We may share Your personal information with Service Providers to
                monitor and analyze the use of our Service, to contact You.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  For business transfers: &nbsp;&nbsp;
                </span>
                We may share or transfer Your personal information in connection
                with, or during negotiations of, any merger, sale of Company
                assets, financing, or acquisition of all or a portion of Our
                business to another company.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  With Affiliates: &nbsp;&nbsp;
                </span>
                We may share Your information with Our affiliates, in which case
                we will require those affiliates to honor this Privacy Policy.
                Affiliates include Our parent company and any other
                subsidiaries, joint venture partners or other companies that We
                control or that are under common control with Us.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  With business partners: &nbsp;&nbsp;
                </span>
                We may share Your information with Our business partners to
                offer You certain products, services or promotions.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  With other users: &nbsp;&nbsp;
                </span>
                when You share personal information or otherwise interact in the
                public areas with other users, such information may be viewed by
                all users and may be publicly distributed outside.
              </p>
              <p className="defination-Sub-contents">
                <span style={{ color: "#8BAFDF" }}>
                  With Your consent: &nbsp;&nbsp;
                </span>
                We may disclose Your personal information for any other purpose
                with Your consent.
              </p>
            </Grid>
          </Grid>
        </div>
        <div>
          <p className="retention-main-heading">
            Retention of Your Personal Data
          </p>
          <p className="retention-content">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p className="retention-content">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
        </div>
        <div>
          <p className="transfer-main-heading">
            Transfer of Your Personal Data
          </p>
          <p className="transfer-content">
            Your information, including Personal Data, is processed at the
            Company&#39;s operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p className="transfer-content">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p className="transfer-content">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
        </div>
        <div>
          <p className="delete-main-heading">Delete Your Personal Data</p>
          <p className="delete-content">
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>
          <p className="delete-content">
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>
          <p className="delete-content">
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>
          <p className="delete-content">
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>
        </div>
        <div>
          <p className="disclosure-main-heading">
            Disclosure of Your Personal Data
          </p>
          <p style={{ color: "#8BAFDF" }} className="disclosure-sub-heading">
            Business Transactions
          </p>
          <p className="disclosure-content">
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <p style={{ color: "#8BAFDF" }} className="disclosure-sub-heading">
            Law enforcement
          </p>
          <p className="disclosure-content">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <p className="other-heading">Other legal requirements</p>
          <p className="other-sub-content-main">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <p className="other-Sub-content">
                Comply with a legal obligation
              </p>
              <p className="other-Sub-content">
                Protect and defend the rights or property of the Company
              </p>
              <p className="other-Sub-content">
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </p>
              <p className="other-Sub-content">
                Protect the personal safety of Users of the Service or the
                public
              </p>
              <p className="other-Sub-content">
                Protect against legal liability
              </p>
            </Grid>
          </Grid>
        </div>
        <div>
          <p className="security-heading">Security of Your Personal Data</p>
          <p className="security-content">
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
        </div>
        <div>
          <p className="children-privacy-heading">Children&#39;s Privacy</p>
          <p className="children-content">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p className="children-content">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent&#39;s consent before We collect and use that
            information.
          </p>
        </div>
        <div>
          <p className="links-heading">Links to Other Websites</p>
          <p className="links-content">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party&#39;s site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p className="links-content">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
        </div>
        <div>
          <p className="changes-privacy-heading">
            Changes to this Privacy Policy
          </p>
          <p className="changes-privacy-content">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="changes-privacy-content">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy Policy.
          </p>
          <p className="changes-privacy-content">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </div>
        <div>
          <p className="contact-Us-heading">Contact Us</p>
          <p className="contact-Us-sub-heading">
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              <p className="contact-Us-content">
                <strong>By email: &nbsp;&nbsp;</strong>contact@auxtomate.com
              </p>
              <p className="contact-Us-content">
                <strong>
                  By visiting this page on our website: &nbsp;&nbsp;
                </strong>
                https://auxtomate.com/talk-to-us
              </p>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Privacy;
