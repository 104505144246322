import { Container, Grid } from "@mui/material";
import React from "react";
import "./exploreproduct.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";

const ExploreProduct = () => {
  return (
    <div className="exploreproductSection-main">
      <Container>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <p className="exploreproductContent">
              What if we say that you can onboard your Partner API in a matter
              of hours at very low build cost and zero maintenance cost? Or
              build your enterprise application instantly with no manual
              intervention at zero build or maintenance cost?
            </p>
          </Grid>
        </Grid>
        <div>
          <Link to="/product" style={{ textDecoration: "none" }}>
            <button className="exploreproductBtn">
              EXPLORE PRODUCTS
              <ArrowRightAltIcon
                style={{
                  color: "#007BFF",
                  fontSize: 30,
                  marginTop: -5,
                  position: "absolute",
                }}
              />
            </button>
          </Link>
        </div>
      </Container>
      <img
        src="images/explorehomepageimg.jpg"
        alt="exploreproduct"
        className="exploreproductimagetwo"
      />
    </div>
  );
};

export default ExploreProduct;
